import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Container, Box, Typography, Link, makeStyles,
} from '@material-ui/core'
import Layout from '../../components/Layout'
import ComponentsList from '../../components/components-list/List'
import FullBleedBanner from '../../components/FullBleedBanner'
import parseUrl from '../../utils/parseUrl'
import NewsletterBlock from '../../components/NewsletterBlock'
import AboutBlock from '../../components/AboutBlock'
import trackLinkClick from '../../utils/trackLinkClick'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.contrastLink,
    textDecoration: 'underline',
  },
  bodyWrapper: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
  },
}))

export const TermsTemplate = ({
  overlayImage,
  title,
  components,
  supportMessage,
  supportEmailLabel,
  supportLink,
}) => {
  const classes = useStyles()

  useEffect(() => {
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebPage',
        breadcrumb: 'Home > Terms',
        id: 'terms',
        name: 'Terms of Use Page',
        page_category: 'WebPage',
      },
    })
  }, [])

  return (
    <Box mb={{ xs: 3, md: 6 }}>
      <FullBleedBanner
        title="TriNet Website Terms of Use"
        overlayImage={overlayImage}
      />
      <Box className={classes.bodyWrapper} pt={{ xs: 10, md: 16 }} pb={{ xs: 12, md: 20 }}>
        <Box textAlign="center">
          <Typography variant="h1">{title}</Typography>
        </Box>
        <ComponentsList components={components} />
        <Container maxWidth="md">
          <Typography variant="body1">
            {supportMessage}
            {' '}
            <Link
              href={parseUrl(supportLink)}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              onClick={trackLinkClick}
            >
              {supportEmailLabel}
            </Link>

          </Typography>
        </Container>
      </Box>
    </Box>
  )
}

TermsTemplate.propTypes = {
  overlayImage: PropTypes.shape({ }),
  title: PropTypes.string.isRequired,
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportMessage: PropTypes.string.isRequired,
  supportEmailLabel: PropTypes.string.isRequired,
  supportLink: PropTypes.string.isRequired,
}

TermsTemplate.defaultProps = {
  overlayImage: null,
}

const TermsOfUsePage = ({
  data: {
    overlayImage,
    pageData: {
      frontmatter: {
        title,
        components,
        supportMessage,
        supportEmailLabel,
        supportLink,
      },
    },
  },
}) => (
  <Layout>
    <TermsTemplate
      overlayImage={overlayImage}
      title={title}
      components={components}
      supportMessage={supportMessage}
      supportEmailLabel={supportEmailLabel}
      supportLink={supportLink}
    />
    <AboutBlock />
    <NewsletterBlock />
  </Layout>
)

TermsOfUsePage.propTypes = {
  data: PropTypes.shape({
    overlayImage: PropTypes.shape({ }),
    pageData: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        components: PropTypes.arrayOf(PropTypes.shape({})),
        supportMessage: PropTypes.string,
        supportEmailLabel: PropTypes.string,
        supportLink: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export const pagesQuery = graphql`
  query termsOfUse {
    overlayImage: file(relativePath: { eq: "overlayhorizontalstatic.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageData: markdownRemark(fields: {slug: {eq: "/pages/terms/"}}) {
      frontmatter {
        title
        supportMessage
        supportEmailLabel
        supportLink
        ...Title
        ...Text
        ...Quote
        ...AudioText
        ...ImageText
        ...Bullets
        ...FullWidthImage
      }
    }
}
`

export default TermsOfUsePage
